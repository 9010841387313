<template>
  <v-treeview
    v-model="treeModel"
    :open="initiallyOpen"
    :items="items"
    item-key="guid"
    activatable
    open-on-click
  >
    <!--  set node icon -->
    <template v-slot:prepend="{ item }">
      <v-icon>
        {{ nodeIcon(item) }}
      </v-icon>
    </template>
    <!--  set node text -->
    <template v-slot:label="{ item }">
      {{ nodeText(item) }}
    </template>
  </v-treeview>
</template>

<script>
// design
import {
  iconAgendaItem,
  iconAgendaSection,
  iconEvent,
  iconFileDetails
} from "@/design/icon/iconConst";

// utils
import { isArray, toLowerCaseSafe } from "@/utils";

export default {
  name: "AgendaTemplateTreeView",
  data() {
    return {
      // initiallyOpen: ["template"],
      treeModel: [],
      items: [],
      // Tree View icons
      iconEvent: iconEvent,
      iconSection: iconAgendaSection,
      iconItem: iconAgendaItem,
      iconMapProperty: iconFileDetails
    };
  },
  props: {
    agendaTemplate: undefined,
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initiallyOpen() {
      return [this.agendaTemplate?.mapping?.guid ?? ""];
    }
  },
  methods: {
    createTreeView(agendaTemplate) {
      this.items = [];

      if (!agendaTemplate) {
        return;
      }

      const count = this.items.push({
        name: agendaTemplate.name,
        type: "template",
        text: agendaTemplate.name,
        guid: agendaTemplate.mapping.guid,
        icon: this.iconEvent
      });

      const root = this.items[count - 1];
      const mappings = agendaTemplate.mapping?.mappings ?? [];

      this.listMappings(mappings, root);
    },
    listMappings(mappings, parent) {
      if (!isArray(mappings) || !parent) {
        return;
      }

      // List, for now, Agenda Sections & Agenda Items only
      const items = mappings.filter(
        // map => map.mappingSourceType === "MappingNewRecord" and/or
        map =>
          toLowerCaseSafe(map.name) === "section" ||
          toLowerCaseSafe(map.name) === "item"
      );

      parent.children = [];

      items.forEach(map => {
        const count = parent.children.push(this.newMapping(map));
        if ((map.mappings?.length ?? 0) > 0) {
          this.listMappings(map.mappings, parent.children[count - 1]);
        }
      });
    },
    nodeIcon(item) {
      return item?.icon;
    },
    nodeText(item) {
      return item?.text;
    },
    newMapping(mapping) {
      return {
        name: mapping.name,
        type: mapping.name,
        text: mapping.text,
        guid: mapping.guid,
        icon: this.getIconName(mapping)
      };
    },
    getIconName(mapping) {
      switch (toLowerCaseSafe(mapping?.name)) {
        case "template": {
          return this.iconEvent;
        }
        case "section": {
          return this.iconSection;
        }
        case "item": {
          return this.iconItem;
        }
        default: {
          return this.iconMapProperty;
        }
      }
    }
  },
  created() {
    if (this.open) {
      this.createTreeView(this.agendaTemplate);
    }
  },
  watch: {
    open(newValue) {
      if (newValue) {
        this.createTreeView(this.agendaTemplate);
      } else {
        this.items = [];
      }
    },
    agendaTemplate(newValue) {
      if (this.open) {
        this.createTreeView(newValue);
      } else {
        this.items = [];
      }
    }
  }
};
</script>
